import React from "react";

const BrandsSection = () => {
  const brands = [
    "microsoft",
    "instagram",
    "netflix",
    "slack",
    "youtube",
    "google",
    "amazon",
    "facebook",
    "apple"
  ];

  return (
    <section className="brands-section">
      <p className="section-secondary-line">Worked with 20+ leading brands</p>
      <div className="brands-container">
        {brands.map((brand, index) => (
          <div className="brand" key={index}>
            <img src={`/${brand}.png`} className="brand-img" alt={brand} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default BrandsSection;
