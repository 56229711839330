import React from "react";
import "./ThankYouPage.css"; // Add your styling here

const ThankYouPage = () => {
  return (
    <div className="thank-you-page">
      <h1>Thank you for purchasing</h1>
      <p>Please click on the button below to join us:</p>
      <a
        href="https://mega.nz/folder/Ich2URYQ#UiD2aYVZntNrQBbll8lPLg"
        className="join-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        Join Us
      </a>
    </div>
  );
};

export default ThankYouPage;
