import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="company-info">
        <img src="/logo.png" className="logo" alt="Logo" />
        <div className="social-links">
          {["instagram", "facebook", "twitter", "youtube"].map(
            (social, index) => (
              <a href="https://www.instagram.com/learnfury/" key={index}>
                <img src={`/${social}.png`} alt={social} />
              </a>
            )
          )}
        </div>
      </div>
      <div className="useful-links footer-links-container">
        <h5 className="footer-links-title">Useful Links</h5>
        <a href="/" className="footer-links">
          Home
        </a>
        <a href="/" className="footer-links">
          Services
        </a>
        <a href="/" className="footer-links">
          Testimonials
        </a>
      </div>
      <div className="information footer-links-container">
        <h5 className="footer-links-title">Information</h5>
        <a href="/" className="footer-links">
          About Us
        </a>
        <a href="/" className="footer-links">
          Privacy Policy
        </a>
        <a href="/" className="footer-links">
          Terms & Conditions
        </a>
        <a href="/" className="footer-links">
          What info we collect ?
        </a>
      </div>
      <div className="contact footer-links-container">
        <h5 className="footer-links-title">Contact Us</h5>
        <p className="footer-text">
          We help self-aware individuals to earn more while doing less.
        </p>
        <p className="footer-text">
          Automation is the only key to success in this competitive world.
        </p>
        <p className="footer-text">Email - support@learnfury.com</p>
      </div>
      <p className="copyright">
        Learnfury Academy © 2024. If you have any inquiries, please contact us
        at <a href="mailto:support@learnfury.com">support@learnfury.com</a>
      </p>
    </footer>
  );
};

export default Footer;
