import React, { useState } from "react";
import { handleDownload } from "../utils/stripe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const HeroSection = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onDownloadClick = async () => {
    setIsLoading(true);
    try {
      await handleDownload();
    } catch (error) {
      console.error("Download error:", error);
      alert(
        "We're sorry, but we couldn't process your download request. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main id="hero-section">
      <div className="hero-info">
        <h1 className="hero-heading">
          TIME<span>is</span>EVERYTHING
        </h1>
        <p className="hero-paragraph">
          <strong>
            <FontAwesomeIcon icon={faCheckCircle} />  Theme page creation guide.
          </strong>
          <br />
          <strong>
            <FontAwesomeIcon icon={faCheckCircle} />  6 digital marketing
            guides.
          </strong>
          <br />
          <strong>
            <FontAwesomeIcon icon={faCheckCircle} />  Automated content creation
            guide.
          </strong>

          <br />
          <strong>
            <FontAwesomeIcon icon={faCheckCircle} />  1000 4k high-quality
            Luxury clips.
          </strong>
          <br />
          <strong>
            <FontAwesomeIcon icon={faCheckCircle} />  High-quality video LUTs.
          </strong>
          <br />
          <strong>
            <FontAwesomeIcon icon={faCheckCircle} />  Private creators
            community.
          </strong>
          <br />
          <strong>
            <FontAwesomeIcon icon={faCheckCircle} />  Amazing bonuses.
          </strong>
          <br />
          <strong>
            <FontAwesomeIcon icon={faCheckCircle} />  Live support.
          </strong>
        </p>
        <div className="hero-action-buttons">
          <button
            onClick={onDownloadClick}
            className="btn primary-btn"
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "GET STARTED FOR $19.99"}
          </button>
        </div>
      </div>
      <div className="hero-section-img-container">
        <div className="background-ele"></div>
        <img
          src="/header.png"
          data-aos="zoom-out"
          className="hero-img"
          alt="Hero"
        />
        <a
          href="https://www.instagram.com/learnfury/"
          className="hero-img-social-link"
          data-aos="zoom-out"
          data-aos-delay="450"
        >
          <img src="/instagram.png" alt="Instagram" />
        </a>
        <div className="subscribers" data-aos="zoom-out" data-aos-delay="300">
          <h1>1.50M+</h1>
          <p>Subscribers</p>
        </div>
        <div className="hero-review" data-aos="zoom-out" data-aos-delay="600">
          <i className="fa-solid fa-quote-left"></i>
          <p>
            When I started my page, I didn't know how I could make it
            successful. These guys just helped me make my dream come true.
          </p>
        </div>
      </div>
    </main>
  );
};

export default HeroSection;
