import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import BrandsSection from "./components/BrandsSection";
import AboutSection from "./components/AboutSection";
import ServicesSection from "./components/ServicesSection";
import FAQSection from "./components/FAQSection";
import ContactSection from "./components/ContactSection";
import Footer from "./components/Footer";
import ThankYouPage from "./ThankYouPage";
import "./App.css";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<>
              <Header />
              <HeroSection />
              <BrandsSection />
              <AboutSection />
              <ServicesSection />
              <FAQSection />
              <ContactSection />
              <Footer />
            </>}
          />
          {/* Add the hidden Thank You Page route */}
          <Route path="/thankyou" element={<ThankYouPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
