import React, { useState } from "react";

const FAQSection = () => {
  const [activeFaq, setActiveFaq] = useState(null);

  const faqs = [
    {
      question: "What is automated digital marketing?",
      answer:
        "Automated digital marketing is the practice of promoting products, services, or brands through digital channels such as search engines, social media, email, and websites. It encompasses various strategies and techniques to reach and engage target audiences in the online space."
    },
    {
      question: "What are the benefits of digital marketing?",
      answer:
        "Digital marketing offers numerous benefits, including: increased brand visibility, better targeting capabilities, cost-effectiveness, measurable results, improved customer engagement, global reach, and the ability to adapt quickly to market changes. It also allows for personalized marketing efforts and real-time interaction with customers."
    },
    {
      question: "What are the different types of digital marketing?",
      answer:
        "The main types of digital marketing include: Search Engine Optimization (SEO), Pay-Per-Click advertising (PPC), Social Media Marketing, Content Marketing, Email Marketing, Affiliate Marketing, Influencer Marketing, and Video Marketing. Each type focuses on different aspects of online promotion and can be used individually or in combination for a comprehensive strategy."
    },
    {
      question: "What is SEO?",
      answer:
        "SEO, or Search Engine Optimization, is the practice of optimizing websites and online content to improve their visibility and ranking in search engine results pages (SERPs). It involves various techniques such as keyword optimization, improving site structure and speed, creating quality content, and building backlinks to increase organic traffic to a website."
    },
    {
      question: "What is content marketing?",
      answer:
        "Content marketing is a strategic approach focused on creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience. The goal is to drive profitable customer action by providing informative or entertaining content that addresses the audience's needs or interests, rather than directly promoting a brand or product."
    }
  ];

  const toggleFaq = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };

  return (
    <section id="faq-section">
      <p className="section-secondary-line">Still have any question</p>
      <h3 className="section-title">Frequently asked questions</h3>
      {faqs.map((faq, index) => (
        <div
          className={`faq ${activeFaq === index ? "active" : ""}`}
          key={index}
        >
          <div className="question-box" onClick={() => toggleFaq(index)}>
            <h3 className="question">{faq.question}</h3>
            <i className="fa-solid fa-caret-up"></i>
          </div>
          <div className="answer-box">
            <p className="answer">{faq.answer}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
