import React from "react";

const ServicesSection = () => {
  const services = [
    {
      img: "market-planning.png",
      name: "Market Planning",
      description:
        "Develop comprehensive strategies to identify and target your ideal audience effectively."
    },
    {
      img: "seo.png",
      name: "SEO & Backlinking",
      description:
        "Optimize your website's visibility in search engines and build high-quality backlinks to boost authority."
    },
    {
      img: "social-marketing.png",
      name: "Social Media Marketing",
      description:
        "Engage your audience across various platforms to build brand awareness and foster customer loyalty."
    },
    {
      img: "digital-marketing.png",
      name: "Digital Marketing",
      description:
        "Implement multi-channel campaigns to maximize your online reach and drive conversions."
    },
    {
      img: "content-marketing.png",
      name: "Content Marketing",
      description:
        "Create valuable, relevant content that attracts and retains a clearly defined audience to drive profitable action."
    },
    {
      img: "market-analysis.png",
      name: "Market Analysis",
      description:
        "Gain insights into market trends, competitor strategies, and customer behavior to inform decision-making."
    }
  ];

  return (
    <section id="service-section">
      <div className="circle right"></div>
      <h3 className="section-title">We offer a wide variety of services</h3>
      <p className="section-secondary-line">to fit your need</p>
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service">
              <div className="service-img">
                <img src={`/${service.img}`} alt={service.name} />
              </div>
              <p className="service-name">{service.name}</p>
            </div>
            <p className="service-info">{service.description}</p>
          </div>
        ))}
      </div>
      <div className="circle left"></div>
    </section>
  );
};

export default ServicesSection;
