import React, { useState } from "react";
import axios from "axios";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    goal: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/api/submit-contact-form", formData);
      alert("Form submitted successfully! We will contact you soon.");
      setFormData({
        name: "",
        email: "",
        goal: "",
        message: ""
      });
    } catch (error) {
      alert("There was an error submitting the form. Please try again.");
      console.error(error);
    }
  };

  return (
    <section id="contact-section">
      {/* No changes to the layout or styling below */}
      <div className="contact-us-img-container">
        <img
          src="/contact.png"
          data-aos="zoom-out"
          className="contact-us-img"
          alt="Contact Us"
        />
        {["instagram", "facebook", "twitter", "youtube"].map(
          (social, index) => (
            <a
              key={index}
              href="/"
              className="contact-img-social-link"
              data-aos="zoom-in-up"
              data-aos-delay={`${250 + index * 50}`}
            >
              <img src={`/${social}.png`} alt={social} />
            </a>
          )
        )}
      </div>
      <div className="contact-us">
        <h3 className="section-title">
          Contact us. So that we can provide the best strategy.
        </h3>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            required
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            required
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="text"
            required
            placeholder="Your business goal"
            name="goal"
            value={formData.goal}
            onChange={handleChange}
          />
          <textarea
            name="message"
            placeholder="Tell us more about your business.."
            required
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <button type="submit" className="btn primary-btn">
            contact us
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
