import React from "react";

const AboutSection = () => {
  const benefits = [
    { img: "grow.png", name: "Get more traffic" },
    { img: "audience.png", name: "Connect with your audience" },
    { img: "sell.png", name: "Sell your product to right audience" },
    { img: "relation.png", name: "Build a strong relation" }
  ];

  return (
    <section id="about-section">
      <div className="about-us-img-container">
        <div className="about-us-img" data-aos="expand">
          <img
            src="/about.png"
            data-aos="zoom-out"
            data-aos-delay="250"
            alt="About Us"
          />
        </div>
        {["instagram", "facebook", "twitter", "youtube"].map(
          (social, index) => (
            <a
              key={index}
              href="/"
              className="about-img-social-link"
              data-aos="zoom-in-up"
              data-aos-delay={`${250 + index * 50}`}
            >
              <img src={`/${social}.png`} alt={social} />
            </a>
          )
        )}
      </div>
      <div className="about-us">
        <p className="section-secondary-line">Target the right audience and</p>
        <h3 className="section-title">Grow your business with us</h3>
        {benefits.map((benefit, index) => (
          <div className="benefit-card" key={index}>
            <div className="card-img">
              <img src={`/${benefit.img}`} alt={benefit.name} />
            </div>
            <p className="card-name">{benefit.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutSection;
