import React, { useState } from "react";

const Header = () => {
  const [isNavActive, setIsNavActive] = useState(false);

  const toggleNav = () => {
    setIsNavActive(!isNavActive);
  };

  return (
    <header>
      <nav className="navbar">
        <img src="/logo.png" className="logo" alt="Logo" />
        <ul className={`links-container ${isNavActive ? "active" : ""}`}>
          <li className="link-items">
            <a href="#hero-section" className="links">
              Home
            </a>
          </li>
          <li className="link-items">
            <a href="#service-section" className="links">
              Services
            </a>
          </li>
          <li className="link-items">
            <a href="#about-section" className="links">
              About us
            </a>
          </li>
          <li className="link-items">
            <a href="#contact-section" className="links">
              Contact Us
            </a>
          </li>
        </ul>
        <div
          className={`nav-toggler ${isNavActive ? "active" : ""}`}
          onClick={toggleNav}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
